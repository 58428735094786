import React from 'react';

import { EButtonVariant } from '../../../../../../../constants/enums';
import AcContentWrapper from '../../../../../../AcContentWrapper/AcContentWrapper';
import DialogModal from '../../../../../../Dialog/Dialog';
import ManageLanguageForm from '../ManageLanguageForm/ManageLanguageForm';

import { EditLanguageModalProps } from './EditLanguageModal.types';

import './style.scss';

const EditLanguageModal = ({currentLanguage, countriesList, isOpen, onClose, onSave}: EditLanguageModalProps) => (
        <DialogModal
            isOpen={isOpen}
            closeButton={true}
            headline={"Edit Language Display Name"}
            width={'392px'}
            withDivider={false}
            content={
                <AcContentWrapper className="addLanguageContent">
                    <ManageLanguageForm
                        countriesList={countriesList}
                        selectedLanguage={currentLanguage}
                        btnVariant={EButtonVariant.CONTAINED}
                        btnText="Save"
                        onSave={onSave}
                    />
                </AcContentWrapper>
            }
            closeDialog={onClose}
        />
    );

export default EditLanguageModal;
