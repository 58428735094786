import { Dialog } from '@mui/material';

import AcDatePicker from '../AcDatePicker/AcDatePicker';

import { DatePickerOverlayProps } from './DatePickerOverlay.types';

import './style.scss';

const DatePickerOverlay = ({
  isOpen,
  setIsOpen,
  isHourPicker = true,
  isHourPickerInDays,
  onApply,
  minDate,
  onApplyButtonText,
  disableFuture,
  pointerCursor = false
}: DatePickerOverlayProps) => {
  return (
    <Dialog open={isOpen} maxWidth="lg">
      <AcDatePicker
        sx={{
          cursor: pointerCursor ? 'pointer' : 'default'
        }}
        isHourPicker={isHourPicker}
        isHourPickerInDays={isHourPickerInDays}
        onApply={(v: any, h: any, notes?: string) => {
          if (!h?.[0] || !h?.[1]) {
            h = undefined;
          }
          onApply(v, h, notes);
          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
        minDate={minDate}
        onApplyButtonText={onApplyButtonText}
        disableFuture={disableFuture}
      />
    </Dialog>
  );
};

export default DatePickerOverlay;
