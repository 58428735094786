import { useNavigate } from 'react-router-dom';

import { QueryCache, useQueryClient } from '@tanstack/react-query';
import usePermissions from 'api/usePermissions';

import { EUserTypeId, Project, UserRole } from '../common/contracts';
import { TIME_TO_STORE_JWT_TOKEN } from '../constants/constants';
import { ELocalStorageKeys } from '../constants/enums';
import { UserDetails } from '../pages/Login/Login.types';
import { localStorageUtil } from '../utils/localStorageUtil';

const queryCache = new QueryCache({
  onError: (error) => {
    console.error(error);
  }
});

export const useLogin = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setPermissions } = usePermissions();

  const saveLoginDetails = (detailsData: {
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
    isSession: boolean;
    token?: string;
    role?: UserRole;
    typeId?: string;
    projects?: Project[];
    version?: number;
    company?: string;
  }) => {
    queryClient.clear();
    if (detailsData.token) {
      localStorageUtil.remove(
        ELocalStorageKeys.JWT_TOKEN,
        detailsData.isSession
      );
      localStorageUtil.remove(
        ELocalStorageKeys.JWT_TOKEN_TTL,
        detailsData.isSession
      );
      localStorageUtil.set(
        ELocalStorageKeys.JWT_TOKEN,
        detailsData.token,
        detailsData.isSession
      );
      localStorageUtil.set(
        ELocalStorageKeys.JWT_TOKEN_TTL,
        new Date(Date.now() + TIME_TO_STORE_JWT_TOKEN).getTime(),
        detailsData.isSession
      );
    }
    const userDetails: UserDetails = {
      email: detailsData.email,
      firstName: detailsData.firstName,
      lastName: detailsData.lastName,
      userId: detailsData.userId,
      userName: `${detailsData.firstName} ${detailsData.lastName}`
    };
    if (detailsData.typeId && detailsData.role) {
      userDetails.role =
        detailsData.typeId === EUserTypeId.APPCHARGE
          ? UserRole.SUPER_ADMIN
          : detailsData.role;
    }
    if (detailsData.projects) {
      userDetails.projects = detailsData.projects;
    }
    if (detailsData.version) {
      userDetails.version = detailsData.version;
      if (detailsData.version === 2) {
        userDetails.role = UserRole.SUPER_ADMIN;
      }
    }
    if (detailsData?.company) {
      userDetails.company = detailsData.company;
    }
    localStorageUtil.set(ELocalStorageKeys.USER_DETAILS, userDetails);
    if (detailsData.version) {
      if (detailsData.version === 2) {
        setPermissions();
      }
    }
  };

  const logout = () => {
    localStorageUtil.removeAny(ELocalStorageKeys.JWT_TOKEN);
    localStorageUtil.removeAny(ELocalStorageKeys.JWT_TOKEN_TTL);
    localStorageUtil.removeAny(ELocalStorageKeys.USER_DETAILS);
    localStorageUtil.removeAny(ELocalStorageKeys.PERMISSIONS);
    localStorageUtil.removeAny(ELocalStorageKeys.FEATURE_FLAGS);
    localStorageUtil.removeAny(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL);

    queryCache.clear();
    queryClient.clear();
    navigate('/');
  };

  const isLoggedIn = () => {
    const isUserAuthenticatedLS = localStorageUtil.getAny(
      ELocalStorageKeys.JWT_TOKEN
    );
    const userAuthTTL = localStorageUtil.getAny(
      ELocalStorageKeys.JWT_TOKEN_TTL
    );
    if (isUserAuthenticatedLS && userAuthTTL)
      return isUserAuthenticatedLS && userAuthTTL - new Date().getTime() > 0;
    return Boolean(localStorageUtil.getAny(ELocalStorageKeys.USER_DETAILS));
  };

  return { saveLoginDetails, logout, isLoggedIn };
};
