import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import useLocalization from '../../../../../api/useLocalization';
import AcCard from '../../../../../components/AcCard/AcCard';
import { DATE_TIME_FORMAT } from '../../../../../constants/constants';
import { ENotificationType } from '../../../../../constants/enums';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { AuthSliceState } from '../../../../../store/store.types';
import { errorResponse } from '../../../../../utils/errorsTextHelper';
import ActionButton from '../../../../ActionButton/ActionButton';

import { ExportTranslationsCSVSectionProps } from './ExportTranslationsCSVSection.types';

import './style.scss';

const ExportTranslationsCSVSection = ({isActive, handleSectionActive}: ExportTranslationsCSVSectionProps) => {
    const formattedDate = dayjs().utc().format(DATE_TIME_FORMAT)
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const {getStaticTranslationsCsvFile, getDynamicTranslationsCsvFile} = useLocalization(currentPublisherId);
    const { enqueueSnackbar } = useNotifications();

    const handleDataExportCSV = async (isDynamic = false) => {
        const response = isDynamic ? await getDynamicTranslationsCsvFile.refetch() : await getStaticTranslationsCsvFile.refetch();
        if (response.isError) {
            enqueueSnackbar(errorResponse(response.error as AxiosError), ENotificationType.ERROR);
            return
        }
            handleSectionActive();
            const { data } = response;
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'translations.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} sx={{position: "relative"}}>
                <AcCard
                    title={"2. Download Current Translations File"}
                    description={"Download translations for static strings (e.g., labels, buttons) and dynamic strings (e.g., user-specific content)"}
                    stackContainer={false}
                    className="localizationCSVCard"
                    customTitleClass="localizationCSVCardTitle"
                    customDescriptionClass="localizationCSVCardDescription"
                    customStyles={{"border": "1px solid #cacbd4", "borderRadius": "4px"}}
                >
                    <Stack direction="row" spacing={2}>
                        <ActionButton
                            variant="outlined"
                            text="Download Static Strings"
                            onClick={() => handleDataExportCSV()}
                        />
                        <ActionButton
                            variant="outlined"
                            text="Download Dynamic Strings"
                            onClick={() => handleDataExportCSV(true)}
                        />
                    </Stack>
                    <Typography variant="body2" sx={{marginTop: '14px', fontSize: '14px', fontFamily: `'Montserrat', sans-serif`}}>{"Last download:"} {formattedDate}</Typography>
                    {!isActive && (
                        <Box className="disabledBox" />
                    )}
                </AcCard>
            </Grid>
        </Grid>
    )
};

export default ExportTranslationsCSVSection;
