import * as yup from 'yup';

export const csvUploadSchema = yup.object().shape({
    file: yup
        .mixed()
        .required('CSV file is required')
        .test(
            'fileFormat',
            'Unsupported Format',
            (value: { type: string }) => value && value.type === 'text/csv'
        )
});
