export enum AssetsCategories {
  all = 'All',
  product = 'Products',
  logo = 'Logo',
  badge = 'Badge',
  bgMobile = 'Mobile Background',
  bgDesk = 'Desktop Background',
  bgBundle = 'Bundle Background',
  bgPopup = 'Popup Background',
  favicon = 'Favicon',
  productPrefix = 'Product Prefix',
  general = 'General',
  playerLevel = 'Player Level',
  section = 'Section'
}
