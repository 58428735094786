import {
  BagelCodeSection,
  DefaultSection,
  PipaSection
} from '@appcharge/shared-ui';

import { ESectionViewModel } from '../constants/enums';

const getSectionLayout = (sectionViewModel: ESectionViewModel) => {
  switch (sectionViewModel) {
    case ESectionViewModel.DEFAULT:
      return DefaultSection;
    case ESectionViewModel.BAGELCODE:
      return BagelCodeSection;
    case ESectionViewModel.PIPA:
      return PipaSection;
  }
};

export default getSectionLayout;
