import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Background, BundlesLayout } from '@appcharge/shared-ui';
import { EBadgePosition } from '@appcharge/shared-ui/lib/components/Helper/enums';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import useUsers from 'api/useUsers';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { transformQuantityValue } from 'utils/formattingUtil';
import * as yup from 'yup';

import useBadges from '../../../api/useBadges';
import useImages from '../../../api/useImages';
import useOffers from '../../../api/useOffers';
import useOffersUI from '../../../api/useOffersUI';
import { useProduct } from '../../../api/useProduct';
import useSections from '../../../api/useSections';
import useSegments from '../../../api/useSegments';
import useTags from '../../../api/useTags';
import useTheme from '../../../api/useTheme';
import {
  Badge,
  BundleBadge,
  DynamicOfferUIBadge,
  EBadgeType,
  Offer,
  OfferUI,
  ProductsSequenceProduct,
  Section,
  User
} from '../../../common/contracts';
import AcAutoComplete from '../../../components/AcAutoComplete/AcAutoComplete';
import AcCard from '../../../components/AcCard/AcCard';
import AcContentWrapper from '../../../components/AcContentWrapper/AcContentWrapper';
import AcInput from '../../../components/AcInput/AcInput';
import AcSelect from '../../../components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from '../../../components/AcSelect/AcSelect.types';
import ActionButton from '../../../components/ActionButton/ActionButton';
import AcViewWrapper from '../../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../../components/DataTable/DataTable';
import DisplayTypeSelect from '../../../components/DisplayTypeSelect/DisplayTypeSelect';
import { Product } from '../../../components/ProductsForm/ProductsForm.types';
import { SectionsItem } from '../../../components/Theme/StoreTheme/storeTheme.types';
import PageTopBar from '../../../components/Topbar/PageTopBar';
import {
  DEFAULT_BACKGROUND_URL,
  MIN_USD_PRICE,
  NEW_VERSION,
  PRICE_VALUE_LIMIT,
  TRIMMED_INPUT_REGEX
} from '../../../constants/constants';
import {
  EAssetType,
  EBundlesInternalViewModel,
  EBundlesViewModel,
  EFeatureFlag,
  ELocalStorageKeys,
  ENotificationType,
  EProductType,
  ESalePercentageDisplayType,
  ESectionViewModel
} from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { useUnsavedChanges } from '../../../hooks/useUnsavedChanges';
import { AuthSliceState } from '../../../store/store.types';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { renderingUtil } from '../../../utils/renderingUtil';

import { SectionLayout } from './components/SectionLayout';

import '../../../style/forms.scss';

interface IOffersFormProps {
  edit?: boolean;
  dup?: boolean;
  isPlayerInfoSync?: boolean;
}

const mapViewModelToBadgeType: Record<EBundlesViewModel, EBadgeType> = {
  [EBundlesViewModel.LINEAR]: EBadgeType.EMBLEM,
  [EBundlesViewModel.THREE_IN_ONE]: EBadgeType.RIBBON,
  [EBundlesViewModel.TWO_IN_ONE]: EBadgeType.RIBBON
};

export const OffersForm: React.FC<IOffersFormProps> = ({
  edit = false,
  dup = false,
  isPlayerInfoSync = false
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;
  const { fetchFeatureFlags } = useUsers(
    false,
    versionDetails,
    currentPublisherId,
    true
  );
  const { offerId } = useParams();
  const navigate = useNavigate();
  const { addOffer, updateOffer, getOffer, getOffers, formatProductQuantity } =
    useOffers(offerId);
  const [currentOfferUI, setCurrentOfferUI] = useState('');
  const { getOfferUI, getOffersUI } = useOffersUI(
    currentPublisherId,
    currentOfferUI
  );
  const hasFeatureFlagTags =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_TAGS];
  const { getStoreTheme } = useTheme(currentPublisherId);
  const [data, setData] = useState<Partial<Offer>>({});
  const { enqueueSnackbar } = useNotifications();
  const [isDisabled, setIsdisabled] = useState(true);
  const { getImages } = useImages(currentPublisherId);
  const { getSegments } = useSegments(currentPublisherId);
  const { getTags } = useTags(currentPublisherId, hasFeatureFlagTags);
  const { getBadges } = useBadges(currentPublisherId);
  const [segmentError, setSegmentError] = useState('');
  const [previewBadge, setPreviewBadge] = useState<any>();
  const [badgeList, setBadgeList] = useState<BundleBadge[]>([]);
  const [skuExists, setSkuExists] = useState<boolean>(false);
  const [isNameExists, setIsNameExists] = useState<boolean>(false);
  const [bundleModel, setBundleModel] = useState<EBundlesInternalViewModel>();
  const { getSections } = useSections(currentPublisherId);
  const [section, setSection] = useState<Partial<SectionsItem>>(
    data?.section || {}
  );
  const [badges, setBadges] = useState(
    getOffer?.data?.result?.dynamicOfferUi?.badges
  );
  const [tagsError, setTagsError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const viewModel = getStoreTheme?.data?.general
    ?.bundlesViewModel as EBundlesViewModel;
  const sectionViewModel = getStoreTheme?.data?.general
    ?.sectionViewModel as ESectionViewModel;
  const checkUniqueness = (value: any, field: keyof Offer) => {
    const isTaken = getOffers.data?.offers.find((o: any) => {
      if (!edit) return o[field] === value;
      return o[field] === value && data[field] !== value;
    });
    return !isTaken;
  };
  const emptySection = { content: 'None', key: '0', value: '0' };

  let offerSchema = yup.object().shape({
    name: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
      .min(3, `Name length should be longer`)
      .required('Name is required')
      .test('uniqueness', 'Name already exists', (value) => {
        return checkUniqueness(value, 'name');
      }),
    offerUiId: yup.string().required('Offer Design is required'),
    publisherOfferId: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
      .required('SKU is required')
      .test('uniqueness', 'SKU already exists', (value) => {
        return checkUniqueness(value, 'publisherOfferId');
      }),
    price: yup
      .number()
      .min(0.8, 'Price must be at least 0.8')
      .max(5000, 'Price must be at most 5000')
      .required('Price is required')
      .test('maxDigits', 'Price must have at most 10 digits', (value) =>
        value ? value.toString().length <= PRICE_VALUE_LIMIT : true
      ),

    salePercentage: yup
      .number()
      .min(0, 'Sale amount cannot be negative')
      .test(
        'is-one-decimal',
        'Sale amount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      ),
    segments: yup.array().of(yup.string()),
    tags: yup.array().of(yup.string())
    // quantity: yup
    //     .string()
    //     .required('Quantity is required')
    //     .test('nonZeroValue', 'Quantity must be at least 1', (value) => {
    //         return value ? value !== '0' : true;
    //     })
    //     .test(
    //         'maxDigits',
    //         'Quantity must have at most 24 digits',
    //         (value) => {
    //             return value ? value.length <= QUANTITY_LIMIT : true;
    //         }
    //     )
  });

  useEffect(() => {
    if (
      (versionDetails === NEW_VERSION &&
        currentOfferUI &&
        currentPublisherId) ||
      (versionDetails !== NEW_VERSION && currentOfferUI)
    ) {
      getOfferUI.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOfferUI, currentPublisherId, versionDetails]);

  useEffect(() => {
    if (data.section) {
      setSection(data.section);
    }
  }, [data]);

  const relevantBadgeType: EBadgeType = useMemo(() => {
    if (
      bundleModel === EBundlesInternalViewModel.LIGHTNING_LINK_CASINO ||
      bundleModel === EBundlesInternalViewModel.HEART_OF_VEGAS
    )
      return EBadgeType.EMBLEM;
    if (bundleModel === EBundlesInternalViewModel.PLAY_SIMPLE)
      return EBadgeType.RIBBON;

    if (viewModel) {
      return mapViewModelToBadgeType[viewModel as EBundlesViewModel];
    }

    return EBadgeType.RIBBON;
  }, [viewModel, bundleModel]);

  const filterRelevantBadges = (badgeType: EBadgeType) => {
    if (
      (viewModel === EBundlesViewModel.LINEAR &&
        badgeType === relevantBadgeType) ||
      (viewModel === EBundlesViewModel.THREE_IN_ONE &&
        badgeType === relevantBadgeType) ||
      (viewModel === EBundlesViewModel.TWO_IN_ONE &&
        badgeType === relevantBadgeType)
    ) {
      return true;
    }
    return false;
  };

  const [products, setProducts] = useState<
    {
      _id: string;
      productId: string;
      image: string;
      imagePrefix: string;
      name: string;
      amount: number | string;
      type: string;
      textFontColorHex: string;
      prefix: string;
      suffix: string;
      publisherProductId?: string;
      quantityDisplay: string;
      priority?: string;
    }[]
  >([]);
  const [currentProduct, setCurrentProduct] =
    useState<ProductsSequenceProduct | null>(null);
  const { data: productsData, isLoading: isLoadingProducts } =
    useProduct(currentPublisherId).getProducts;

  useEffect(() => {
    if (!!offerId && !getOffer.isLoading && getOffer.data) {
      setData(getOffer.data.result);
      setCurrentOfferUI(getOffer.data.result.offerUi?._id || '');
      setProducts(
        getOffer.data.result.productsSequence[0].products.map(
          (
            p: {
              product: Partial<Product>;
              quantity: number;
              quantityDisplay: string;
            },
            index: number
          ) => {
            return {
              _id: p.product?.productId || index,
              productId: p.product?.productId,
              image: p.product?.images?.[0].url,
              imagePrefix: p.product?.images?.find(
                (i) => i.type === EAssetType.PRODUCT_PREFIX
              )?.url,
              name: p.product?.name,
              amount: p?.quantity,
              type: p.product?.type,
              textFontColorHex: p.product?.textFontColorHex,
              prefix: p.product?.prefix,
              suffix: p.product?.suffix,
              publisherProductId: p.product?.publisherProductId,
              quantityDisplay: p.quantityDisplay || '',
              priority: p.product?.priority
            };
          }
        )
      );
    }
  }, [getOffer.data, getOffer.isLoading, offerId]);

  useEffect(() => {
    if (
      (versionDetails === NEW_VERSION && offerId && currentPublisherId) ||
      (versionDetails !== NEW_VERSION && offerId)
    ) {
      getOffer.refetch();
    }
  }, [versionDetails, offerId, currentPublisherId]);

  useEffect(() => {
    const newSection = getSections.data?.result?.find(
      (section: Partial<SectionsItem>) =>
        section.sectionId === getOffer.data?.result?.sectionId?.sectionId ||
        getOffer.data?.result?.section?.sectionId
    );
    setSection(newSection ?? {});
  }, [getOffer.data, getSections.data]);

  const {
    values,
    handleChange,
    handleBlur,
    submitForm,
    dirty,
    isValid,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setTouched,
    validateField
  } = useFormik({
    validateOnMount: true,
    validationSchema: offerSchema,
    enableReinitialize: true,
    initialValues: {
      publisherOfferId: data.publisherOfferId || '',
      price: data.productsSequence?.[0]?.priceInUsdCents
        ? new Decimal(data.productsSequence?.[0]?.priceInUsdCents)
            .div(100)
            .toNumber()
        : MIN_USD_PRICE,
      name: data.name ? `${data.name}${dup ? '_copy' : ''}` : '',
      type: data.type || '',
      offerUiId: data.offerUi?._id || data.offerUi?.offerUiId || '',
      segments: data.segments || [],
      tags: data.tags || [],
      quantity: '',
      productsSequence: data.productsSequence || [{ products: [] }],
      salePercentage: data.dynamicOfferUi?.salePercentage || 0,
      salePercentageDisplayType:
        data.dynamicOfferUi?.salePercentageDisplayType ||
        ESalePercentageDisplayType.PERCENTAGE,
      badge:
        data.dynamicOfferUi?.badges?.find((b) => {
          return filterRelevantBadges(b.badge!.type);
        })?.badge?.badgeId || null,
      section: data?.section?.sectionId || data?.sectionId?.sectionId || '0',
      backgroundImage: data?.offerUi?.backgroundImage || ''
    },
    onSubmit: async (values) => {
      const today = new Date();
      const in100years = new Date(
        today.getTime() + 60000 * 60 * 24 * 365 * 100
      );
      let dynamicOfferUiBadges: DynamicOfferUIBadge[] = [];
      if (values.badge) {
        // find badge by ID to get its position
        const badge = getBadges.data.result.find(
          (b: Badge) => b.badgeId === values.badge
        );
        const b: DynamicOfferUIBadge = {
          badgeId: values.badge,
          publisherBadgeId: 'N/A'
        };
        dynamicOfferUiBadges.push(b);
      }
      // if (isPlayerInfoSync && products.length === 0) {
      //     setFieldError('quantity', 'At least one product is required');
      //     return;
      // }

      const newOffer: Partial<Offer> & { sectionId?: string | null } = {
        publisherOfferId: values.publisherOfferId,
        name: values.name,
        type: 'Bundle',
        active: true,
        offerUiId: values.offerUiId,
        segments: values.segments,
        tags: values.tags,
        productsSequence: [
          {
            index: 1,
            products: products.map((p) => ({
              productId: p.productId,
              quantity: p.amount,
              publisherProductId: p.publisherProductId
            })),
            priceInUsdCents: new Decimal(values.price).mul(100).toNumber()
            // playerAvailability: 1
          }
        ],
        dynamicOfferUi: {
          badges: dynamicOfferUiBadges,
          salePercentage: values.salePercentage,
          salePercentageDisplayType:
            values.salePercentageDisplayType ||
            ESalePercentageDisplayType.PERCENTAGE
        },
        sectionId:
          values.section && values.section !== '0' ? values.section : null
      };
      if (edit && offerId && !dup) {
        updateOffer.mutate(
          { offerId, form: newOffer },
          {
            onSuccess: () => {
              enqueueSnackbar(
                'Offer edited successfully',
                ENotificationType.SUCCESS
              );
              navigate('../');
            },
            onError: () => {
              enqueueSnackbar('Error editing offer', ENotificationType.ERROR);
            }
          }
        );
      } else {
        addOffer.mutate(newOffer, {
          onSuccess: () => {
            enqueueSnackbar(
              'New Offer added successfully',
              ENotificationType.SUCCESS
            );
            navigate('../');
          },
          onError: () => {
            enqueueSnackbar('Error adding Offer', ENotificationType.ERROR);
          }
        });
      }
    }
  });

  useEffect(() => {
    if (values.badge !== undefined) {
      const currentBadge = getBadges?.data?.result?.find(
        (b: any) => b.badgeId === values.badge
      );
      setBadges([{ badge: currentBadge }]);
    } else {
      const badges = getOffer?.data?.result?.dynamicOfferUi?.badges;
      if (badges && badges?.length > 0) {
        setBadges(badges);
      }
    }
  }, [values?.badge]);

  const removeProduct = (productId: string) => {
    const newProducts = products.filter((p) => p._id !== productId);
    setProducts(newProducts);

    setFieldValue(
      'productsSequence[0].products',
      newProducts.map((p) => ({
        productId: p.productId,
        quantity: p.amount
      }))
    );
  };

  const editProduct = (productId: string) => {
    return products.forEach((p) => {
      if (p._id === productId) {
        removeProduct(productId);
        setCurrentProduct({
          product: p.image,
          productId: p._id,
          quantity: p.amount,
          publisherProductId: p.publisherProductId
        });
      }
    });
  };

  useUnsavedChanges({ dirty });

  const addProduct = async () => {
    const foundProduct = productsData!.result.find(
      (product: { productId: string | undefined }) =>
        product.productId === currentProduct?.productId
    );

    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: Number(currentProduct!.quantity),
      productType: foundProduct.type
    });

    const newProduct = {
      _id: currentProduct!.productId!,
      productId: currentProduct!.productId!,
      image: foundProduct!.images[0].url,
      imagePrefix: foundProduct.images?.find((i: { type: EAssetType }) => {
        return i.type === EAssetType.PRODUCT_PREFIX;
      })?.url,
      name: foundProduct!.name,
      amount: currentProduct!.quantity,
      type: foundProduct!.type,
      textFontColorHex: foundProduct!.textFontColorHex,
      prefix: foundProduct!.prefix,
      suffix: foundProduct!.suffix,
      quantityDisplay: formattedProductQuantity.data,
      priority: foundProduct?.priority
    };

    if (currentProduct?.publisherProductId) {
      (newProduct as any).publisherProductId =
        currentProduct.publisherProductId;
    }

    setProducts([...products, newProduct]);

    if ((newProduct as any)?.publisherProductId) {
      setFieldValue('productsSequence[0].products', [
        ...values.productsSequence[0].products,
        {
          productId: newProduct.productId,
          quantity: newProduct.amount,
          publisherProductId: (newProduct as any).publisherProductId
        }
      ]);
    } else {
      setFieldValue('productsSequence[0].products', [
        ...values.productsSequence[0].products,
        {
          productId: newProduct.productId,
          quantity: newProduct.amount
        }
      ]);
    }

    setCurrentProduct({
      productId: '',
      quantity: 0,
      publisherProductId: ''
    });
  };

  useEffect(() => {
    setIsdisabled(!isValid || !dirty || isSubmitting);
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    const badges = [];
    if (values.badge) {
      const badge = getBadges.data.result.find(
        (b: Badge) => b.badgeId === values.badge
      );
      badges.push({
        type: badge.type,
        salesPercentage: 1,
        ribbon: badge.ribbon,
        emblem: badge.emblem,
        publisherBadgeId: 'N/A',
        position: 'left'
      });
    }
    setPreviewBadge(badges);
  }, [values.badge]);

  useEffect(() => {
    if (!getBadges.data?.result) return;
    const badgesDropdownItems = getBadges.data?.result
      .map((badge: Badge) => {
        if (filterRelevantBadges(badge.type))
          return {
            content: badge.name,
            name: badge.name,
            key: badge.badgeId,
            value: badge.badgeId,
            url: badge.emblem?.imageUrl,
            position: badge.position as EBadgePosition
          };
      })
      .filter((b: any) => b !== undefined);
    setBadgeList(badgesDropdownItems);
  }, [getBadges.data, viewModel, bundleModel]);

  // removes segments
  const removeSegment = (segment: string) => {
    const filteredSegments = values.segments.filter((s) => s !== segment);
    setFieldValue('segments', filteredSegments);
  };

  const removeTag = (tag: string) => {
    const filteredTags = values.tags.filter((s) => s !== tag);
    setFieldValue('tags', filteredTags);
  };

  useEffect(() => {
    setSkuExists(
      getOffers.data?.offers.find((o: any) => {
        return o.publisherOfferId === values.publisherOfferId;
      }) && !edit
    );
  }, [values.publisherOfferId]);

  useEffect(() => {
    setIsNameExists(
      getOffers.data?.offers.find((o: any) => {
        return o.name === values.name;
      }) && !edit
    );
  }, [values.name]);

  useEffect(() => {
    if (!getStoreTheme.data?.general?.bundlesInternalViewModel) return;
    setBundleModel(getStoreTheme.data.general.bundlesInternalViewModel);
  }, [getStoreTheme.data]);

  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newOffersQuantity =
      e.target.value.length !== 0
        ? transformQuantityValue(e.target.value)
        : e.target.value;
    setTouched({ ...touched, quantity: true });
    handleChange({
      target: {
        name: 'quantity',
        value: String(newOffersQuantity)
      }
    });
    setCurrentProduct({
      productId: currentProduct?.productId || '',
      quantity: newOffersQuantity,
      publisherProductId: currentProduct?.publisherProductId
    });
    validateField('quantity');
  };

  const transformPriceValue = () => {
    if (!values.price) return MIN_USD_PRICE;
    return String(values.price).length > PRICE_VALUE_LIMIT
      ? parseFloat(String(values.price).slice(0, PRICE_VALUE_LIMIT))
      : values.price;
  };

  const getSectionItems = () => {
    let sectionsData = [emptySection];
    if (getSections?.data?.result.length > 0) {
      const parsedSectionsData = getSections.data.result.map(
        (section: Section) => ({
          content: section.name,
          key: section.sectionId,
          value: section.sectionId
        })
      );
      return sectionsData.concat(parsedSectionsData);
    }
    return sectionsData;
  };

  const handleSectionChange = (e: SelectChangeEvent<unknown>) => {
    const newSection = getSections.data.result.find(
      (section: Partial<SectionsItem>) => section.sectionId === e.target.value
    );
    setSection(newSection ?? {});
    handleChange(e);
  };

  return (
    <AcViewWrapper
      header={
        <PageTopBar
          disable={false}
          headline={`${edit ? 'Edit' : 'New'} Bundle ${
            edit
              ? `(${
                  !getOffer.isLoading && getOffer.data?.result
                    ? getOffer.data?.result?.name
                    : '...'
                })`
              : ''
          }`}
          buttons={[
            {
              text: 'Save',
              action: () => submitForm()
              // disabled:
              //     !permissionsUtil.canUserEdit() || isDisabled
            }
          ]}
          backFunction={() => navigate(-1)}
        />
      }
    >
      {!getImages.isLoading && getImages.data && (
        <AcContentWrapper className="formContent">
          <Grid container pt={'24px'}>
            <Grid item xs={5}>
              <Stack>
                <AcCard stackContainer={false} title="General">
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcInput
                        header="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={
                          (touched.name && Boolean(errors.name)) || isNameExists
                        }
                        helperText={
                          (touched.name ? errors.name?.toString() : '') ||
                          (isNameExists ? 'Name already exists' : '')
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcInput
                        header="SKU"
                        name="publisherOfferId"
                        value={values.publisherOfferId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={
                          Boolean(errors.publisherOfferId) &&
                          (touched.publisherOfferId || dup)
                        }
                        helperText={
                          touched.publisherOfferId || dup
                            ? errors.publisherOfferId?.toString()
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Offer Design"
                        name="offerUiId"
                        value={values.offerUiId}
                        required={true}
                        onChange={(e) => {
                          setCurrentOfferUI(e.target.value as string);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        items={getOffersUI?.data?.result
                          .filter((oi: OfferUI) => oi.offerUiType === 'Bundle')
                          .map((offerUI: OfferUI) => {
                            return {
                              content: offerUI.name,
                              key: offerUI._id,
                              value: offerUI._id
                            };
                          })}
                        error={touched.offerUiId && Boolean(errors.offerUiId)}
                        helperText={
                          touched.offerUiId ? errors.offerUiId?.toString() : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Section"
                        name="section"
                        value={values.section}
                        required={false}
                        onChange={(e) => handleSectionChange(e)}
                        onBlur={handleBlur}
                        items={getSectionItems()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcInput
                        header="Price"
                        name="price"
                        value={values.price}
                        onChange={(e) => {
                          setTouched({
                            ...touched,
                            price: true
                          });
                          handleChange(e);
                          validateField('price');
                        }}
                        onBlur={handleBlur}
                        type="number"
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: {
                            min: MIN_USD_PRICE
                          }
                        }}
                        error={touched.price && Boolean(errors.price)}
                        helperText={
                          touched.price ? errors.price?.toString() : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
                <AcCard stackContainer={false} title="Segments">
                  <Grid container columnSpacing={1.5}>
                    <Grid xs={12} item>
                      <AcAutoComplete
                        ref={inputRef}
                        value={inputValue}
                        startAdornment={<GridSearchIcon />}
                        helperText="Press ‘Enter’ to add a new segment"
                        onEnter={(val) => {
                          setFieldValue('segments', [...values.segments, val]);
                          setInputValue('');
                          inputRef?.current?.blur();
                        }}
                        onChangeEvent={(val) =>
                          setSegmentError(
                            values.segments.includes(val)
                              ? "You can't add the same segment twice"
                              : ''
                          )
                        }
                        options={
                          (!getSegments?.isLoading &&
                            getSegments?.data.filter(
                              (s: string) => !values.segments.includes(s)
                            )) ||
                          []
                        }
                        additionalOptions={values.segments}
                        onChange={(e) => {
                          const selected = (e.target as HTMLElement)
                            .textContent;
                          selected &&
                            setFieldValue('segments', [
                              ...values.segments,
                              selected
                            ]);
                        }}
                        onBlur={handleBlur}
                        error={segmentError}
                      />
                      <Stack></Stack>
                      {!!values.segments.length && (
                        <Stack
                          marginTop={1.5}
                          gap={1}
                          direction="row"
                          flexWrap="wrap"
                        >
                          {values.segments.map((val) => (
                            <Chip
                              className="segment-chip"
                              key={val}
                              onClick={() => removeSegment(val)}
                              label={
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap={0.5}
                                >
                                  <span>{val}</span>
                                  <CloseIcon
                                    sx={{
                                      width: 14,
                                      height: 14
                                    }}
                                  />
                                </Stack>
                              }
                            />
                          ))}
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </AcCard>
                {/* <Divider /> */}
                {/* <AcCard stackContainer={false} title="Badges">
                                    <Grid container columnSpacing={1.5}>
                                        <Grid item xs={6}>
                                            <AcSelect
                                                header={
                                                    viewModel ===
                                                    EBundlesViewModel.THREE_IN_ONE
                                                        ? 'Center'
                                                        : 'Left'
                                                }
                                                name="badge"
                                                value={values.badge}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                renderType={
                                                    EAcSelectItemRenderType.TEXT
                                                }
                                                items={badgeList}
                                            />
                                        </Grid>
                                    </Grid>
                                </AcCard> */}
                <Divider />
                {relevantBadgeType === EBadgeType.RIBBON && (
                  <>
                    <AcCard stackContainer={false} title="Badges">
                      <Grid container columnSpacing={1.5}>
                        <Grid item xs={12}>
                          <AcSelect
                            header="Ribbon"
                            name="badge"
                            value={values.badge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            renderType={EAcSelectItemRenderType.TEXT}
                            items={badgeList}
                            onClear={() => {
                              setFieldValue('badge', '');
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AcCard>
                    <Divider />
                  </>
                )}
                {relevantBadgeType === EBadgeType.EMBLEM && (
                  <>
                    <AcCard stackContainer={false} title="Badges">
                      <Grid container columnSpacing={1.5}>
                        <Grid item xs={12}>
                          <AcSelect
                            header="Emblem"
                            name="badge"
                            value={values.badge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            renderType={EAcSelectItemRenderType.TEXT}
                            items={badgeList}
                            onClear={() => {
                              setFieldValue('badge', '');
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AcCard>
                    <Divider />
                  </>
                )}
                <AcCard stackContainer={false} title="Sale">
                  <Grid container columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcInput
                        header="Amount"
                        name="salePercentage"
                        value={values.salePercentage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        InputProps={{
                          startAdornment:
                            values?.salePercentageDisplayType ===
                            ESalePercentageDisplayType.PERCENTAGE ? (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ) : undefined,
                          inputProps: {
                            min: 0,
                            step: 0.1
                          }
                        }}
                        error={
                          touched.salePercentage &&
                          Boolean(errors.salePercentage)
                        }
                        helperText={
                          touched.salePercentage
                            ? errors.salePercentage?.toString()
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DisplayTypeSelect
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.salePercentageDisplayType}
                      />
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
                <AcCard stackContainer={false} title="Products">
                  <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Name"
                        name="name"
                        value={currentProduct?.productId || ''}
                        onChange={(e) => {
                          setCurrentProduct({
                            ...currentProduct,
                            productId: (e.target.value as string) || '',
                            quantity: currentProduct?.quantity || 0
                          });
                        }}
                        onBlur={handleBlur}
                        renderType={EAcSelectItemRenderType.IMAGE}
                        items={
                          (!isLoadingProducts &&
                            productsData &&
                            productsData.result
                              ?.map((product: Product) => {
                                if (
                                  !products.find(
                                    (p) => p.productId === product.productId
                                  )
                                ) {
                                  return {
                                    url: product.images?.[0]?.url,
                                    name: product.name,
                                    content: product.name,
                                    key: product.productId,
                                    value: product.productId
                                  };
                                }
                                return undefined;
                              })
                              ?.filter((v: any) => v !== undefined)) ||
                          []
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AcInput
                        header="Quantity"
                        name="quantity"
                        value={String(currentProduct?.quantity)}
                        type="number"
                        inputProps={{
                          min: 0
                        }}
                        onChange={(e) => handleQuantityChange(e)}
                        error={touched.quantity && Boolean(errors.quantity)}
                        helperText={
                          touched.quantity ? errors.quantity?.toString() : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ActionButton
                        text={'Add Product'}
                        variant="outlined"
                        disabled={
                          !currentProduct?.productId ||
                          !currentProduct?.quantity ||
                          (products.length >= 1 &&
                            (bundleModel === EBundlesInternalViewModel.MATCH ||
                              bundleModel ===
                                EBundlesInternalViewModel.COMMUNIX))
                        }
                        onClick={addProduct}
                      />
                      {(bundleModel === EBundlesInternalViewModel.MATCH ||
                        bundleModel === EBundlesInternalViewModel.COMMUNIX) &&
                        products.length >= 1 && (
                          <div
                            style={{
                              marginTop: '12px'
                            }}
                          >
                            *You can only add one product
                          </div>
                        )}
                    </Grid>

                    <Grid item xs={12} mt={-3}>
                      <DataTable
                        columns={[
                          {
                            field: 'image',
                            headerName: '',
                            flex: 1,
                            renderCell: (params: any) => {
                              return (
                                <img
                                  style={{
                                    maxHeight: '50%',
                                    objectFit: 'contain'
                                  }}
                                  src={params.row.image}
                                  alt="productImage"
                                />
                              );
                            }
                          },
                          {
                            field: 'name',
                            headerName: 'Name',
                            flex: 1
                          },
                          {
                            field: 'amount',
                            headerName: 'Amount',
                            flex: 1
                          },
                          {
                            field: 'type',
                            headerName: 'Type',
                            flex: 1
                          },
                          {
                            field: 'remove',
                            headerName: 'Remove',
                            renderCell: (params) => (
                              <IconButton
                                onClick={() => removeProduct(params.row._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ),
                            flex: 1
                          },
                          {
                            field: 'edit',
                            headerName: 'Edit',
                            renderCell: (params) => (
                              <IconButton
                                onClick={() => editProduct(params.row._id)}
                              >
                                <EditIcon />
                              </IconButton>
                            ),
                            flex: 1
                          }
                        ]}
                        rows={products}
                        loading={false}
                      />
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
                {hasFeatureFlagTags && (
                  <AcCard stackContainer={false} title="Tags">
                    <Grid container columnSpacing={1.5}>
                      <Grid xs={12} item>
                        <AcAutoComplete
                          ref={inputRef}
                          value={inputValue}
                          startAdornment={<GridSearchIcon />}
                          helperText="Press ‘Enter’ to add a new tag"
                          onEnter={(val) => {
                            setFieldValue('tags', [...values.tags, val]);
                            setInputValue('');
                            inputRef?.current?.blur();
                          }}
                          onChangeEvent={(val) =>
                            setTagsError(
                              values.tags.includes(val)
                                ? "You can't add the same tag twice"
                                : ''
                            )
                          }
                          onBlur={handleBlur}
                          options={
                            (!getTags?.isLoading &&
                              getTags?.data.filter(
                                (s: string) => !values.tags.includes(s)
                              )) ||
                            []
                          }
                          additionalOptions={values.tags}
                          onChange={(e) => {
                            const selected = (e.target as HTMLElement)
                              .textContent;
                            selected &&
                              setFieldValue('tags', [...values.tags, selected]);
                          }}
                          error={tagsError}
                        />
                        {!!values.tags.length && (
                          <Stack
                            marginTop={1.5}
                            gap={1}
                            direction="row"
                            flexWrap="wrap"
                          >
                            {values.tags.map((val) => (
                              <Chip
                                className="tags-chip"
                                key={val}
                                onClick={() => removeTag(val)}
                                label={
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <span>{val}</span>
                                    <CloseIcon
                                      sx={{
                                        width: 14,
                                        height: 14
                                      }}
                                    />
                                  </Stack>
                                }
                              />
                            ))}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </AcCard>
                )}
              </Stack>
            </Grid>

            <Grid item xs={7} className="iphone-mock-wrapper">
              <div className="iphone-mock">
                <Background
                  backgroundImageMobile={
                    !getStoreTheme.isLoading &&
                    getStoreTheme.data.general?.backgroundImageMobile
                  }
                  backgroundImageDesktop={
                    !getStoreTheme.isLoading &&
                    getStoreTheme.data.general?.backgroundImageDesktop
                  }
                  width="100%"
                  height="100%"
                  position="absolute"
                />
                <SectionLayout
                  sectionViewModel={sectionViewModel}
                  section={section}
                />
                <BundlesLayout bundlesViewModel={viewModel} isPreview={true}>
                  <div
                    className="bundles-wrapper"
                    style={{
                      minHeight: '200px',
                      justifyContent: 'space-between'
                    }}
                  >
                    {!getStoreTheme.isLoading &&
                      Array.from(
                        {
                          length:
                            viewModel === EBundlesViewModel.TWO_IN_ONE
                              ? 2
                              : viewModel === EBundlesViewModel.THREE_IN_ONE
                                ? 3
                                : 1
                        },
                        () => null
                      ).map((_, index) =>
                        renderingUtil.renderBundleByInternalModel(
                          getStoreTheme.data.general.bundlesInternalViewModel,
                          {
                            index: index,
                            badges: badges,
                            backgroundSrc:
                              getOfferUI.data?.backgroundImage ||
                              values?.backgroundImage ||
                              DEFAULT_BACKGROUND_URL,
                            bundleBorderColor:
                              getStoreTheme.data.storeScreen.bundleBorderColor,
                            productList:
                              products.length > 0
                                ? products.map((p) => {
                                    return {
                                      quantityDisplay:
                                        p?.quantityDisplay || p?.amount,
                                      images: {
                                        product: p?.image,
                                        productPrefix: p?.imagePrefix
                                      },
                                      textFontColorHex: p?.textFontColorHex,
                                      type: 't' as EProductType,
                                      priority: p?.priority,
                                      prefix: p?.prefix,
                                      suffix: p?.suffix
                                    };
                                  })
                                : [],
                            bundleButtonColor:
                              getStoreTheme.data.general.buttonColor,
                            bundleButtonTextColor:
                              getStoreTheme.data.general.buttonTextColor,
                            fontFamily: getStoreTheme.data.general.font,
                            price: {
                              price: transformPriceValue(),
                              isCents: false,
                              fractionalSeparator: '.',
                              milSeparator: ',',
                              spacing: false,
                              symbolPosition: 'left',
                              currencySymbol: '$',
                              currencyCode: 'USD'
                            },
                            salePercentage: values?.salePercentage,
                            salePercentageDisplayType:
                              values?.salePercentageDisplayType,
                            gameName:
                              getStoreTheme.data.general
                                .bundlesInternalViewModel
                          }
                        )
                      )}
                  </div>
                </BundlesLayout>
              </div>
            </Grid>
          </Grid>
        </AcContentWrapper>
      )}
    </AcViewWrapper>
  );
};
