import { useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Grid, Stack } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { ScheduleTimeFrame, TimeFrameTableData } from 'common/contracts';
import {
  EIntervalStatus,
  ELocalStorageKeys,
  EScheduleType
} from 'constants/enums';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import {
  handleDateRangeApplied,
  handleSameDateRangeApplied
} from 'utils/getDateRange';

import AcCard from 'components/AcCard/AcCard';
import AcSwitchSelector from 'components/AcSwitchSelector/AcSwitchSelector';
import ActionButton from 'components/ActionButton/ActionButton';
import DataTable from 'components/DataTable/DataTable';
import DatePickerOverlay from 'components/DatePickerOverlay/DatePickerOverlay';

const ScheduleTab = ({ values, formikProps }: any) => {
  const { setFieldValue } = formikProps;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [rangeDates, setRangeDates] = useState<any>([null, null]);
  const [isSingleDay, setIsSingleDay] = useState(false);
  const [, setIsToday] = useState(false);
  const [, setHours] = useState<string[]>([]);
  const [formattedRangeDates, setFormattedRangeDates] = useState<
    string[] | null[]
  >([null, null]);
  const [formattedDates, setFormattedDates] = useState('');
  const [, setPreviousFormattedRangeDates] = useState<string[] | null[]>([
    null,
    null
  ]);
  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [isPermanent, setIsPermanent] = useState(true);
  const [intervalsTableData, setIntervalsTableData] = useState<
    TimeFrameTableData[]
  >([]);
  const [editData, setEditData] = useState<ScheduleTimeFrame | null>(null);

  useEffect(() => {
    setIsPermanent(values.timeFrames.length > 0);
  }, []);

  useEffect(() => {
    const timeFramesData: TimeFrameTableData[] = values.timeFrames.map(
      (date: ScheduleTimeFrame, index: number) => ({
        id: date.id || `id-${index}`,
        startTime: date.startTime,
        endTime: date.endTime,
        notes: date.notes,
        status: statusValue
      })
    );

    setIntervalsTableData(timeFramesData);
  }, [values.timeFrames, statusValue]);

  // Helper function to determine the status based on current date and time frame
  const determineStatus = (startTime: Date, endTime: Date): EIntervalStatus => {
    const currentDate = new Date();

    if (currentDate >= startTime && currentDate <= endTime) {
      return EIntervalStatus.RUNNING;
    } else if (currentDate < startTime) {
      return EIntervalStatus.UPCOMING;
    } else {
      return EIntervalStatus.ENDED;
    }
  };

  const handleEditInterval = (interval: ScheduleTimeFrame) => {
    setIsDatePickerOpen(true);
    setEditData({
      startTime: interval.startTime,
      endTime: interval.endTime
    });
  };

  const handleApply = (range: any, hours: [string, string], notes?: string) => {
    setHours(hours);
    setIsSingleDay(!!hours);

    const [fromUtcDate, toUtcDate] = range;

    const newTimeFrame: ScheduleTimeFrame = {
      startTime: new Date(fromUtcDate),
      endTime: new Date(toUtcDate),
      notes
    };

    if (editData) {
      // Update the existing time frame that matches editData
      const updatedTimeFrames = values.timeFrames.map(
        (timeFrame: ScheduleTimeFrame) =>
          timeFrame.startTime === editData.startTime &&
          timeFrame.endTime === editData.endTime
            ? newTimeFrame // Replace old time frame
            : timeFrame // Keep unchanged time frames
      );
      setFieldValue('timeFrames', updatedTimeFrames);
    } else {
      // Add a new time frame
      setFieldValue('timeFrames', [...values.timeFrames, newTimeFrame]);
    }

    setEditData(null);

    const handler = hours ? handleSameDateRangeApplied : handleDateRangeApplied;

    handler({
      range,
      hours,
      setRangeDates,
      setFormattedRangeDates,
      setPreviousFormattedRangeDates
    });
  };

  const options = [
    { value: EScheduleType.PERMANENT },
    { value: EScheduleType.SCHEDULED }
  ];

  const columns = [
    {
      field: 'startTime',
      headerName: 'Start',
      disableReorder: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'endTime',
      headerName: 'End',
      disableReorder: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'notes',
      headerName: 'Notes',
      disableReorder: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => {
        const startTime = new Date(row.row.startTime);
        const endTime = new Date(row.row.endTime);
        const status = determineStatus(startTime, endTime);
        return (
          <StatusLabel
            text={status}
            status={
              status === EIntervalStatus.RUNNING
                ? EStatusLabel.ACTIVE
                : status === EIntervalStatus.UPCOMING
                  ? EStatusLabel.PENDING
                  : EStatusLabel.FAILED
            }
            prefixIcon={
              status === EIntervalStatus.RUNNING ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ScheduleIcon />
              )
            }
          />
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      disableReorder: true,
      width: 50,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          showInMenu
          disabled={
            new Date() >= params.row.startTime &&
            new Date() <= params.row.endTime
          }
          onClick={() => handleEditInterval(params.row)}
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          showInMenu
          onClick={() => {
            const newTimeFrames = values.timeFrames.filter(
              (tf: ScheduleTimeFrame) => {
                return (
                  tf.startTime !== params.row.startTime &&
                  tf.endTime !== params.row.end
                );
              }
            );
            setFieldValue('timeFrames', newTimeFrames);
          }}
        />
      ]
    }
  ];

  return (
    <Stack>
      <DatePickerOverlay
        isOpen={isDatePickerOpen}
        setIsOpen={setIsDatePickerOpen}
        onApply={handleApply}
        onApplyButtonText="Add Interval"
        disableFuture={false}
        isHourPicker
        pointerCursor
      />

      <AcCard stackContainer={false}>
        <Grid container rowSpacing={2} columnSpacing={1.5}>
          <Grid item xs={6}>
            <AcSwitchSelector
              options={options}
              onClick={() => {}}
              value={values.timeFrames.length === 0}
            />
          </Grid>
        </Grid>
      </AcCard>
      <Stack
        spacing={2}
        sx={{
          marginTop: '2rem'
        }}
      >
        <AcCard stackContainer={false} title="Schedule new interval">
          <Grid container sx={{ flexDirection: 'column' }}>
            <Grid item xs={3}>
              <Stack
                direction="column"
                justifyContent="center"
                padding="16px 0"
              >
                <ActionButton
                  text="Add Interval"
                  onClick={() => setIsDatePickerOpen(true)}
                  style={{
                    width: '195px'
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </AcCard>

        <Stack width="auto">
          <DataTable
            columns={columns}
            rows={intervalsTableData}
            loading={false}
            rowIdIdentifier="id"
            defaultHiddenFields={['id']}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ScheduleTab;
