import React from 'react';

import { EButtonColor } from '../../../../../../../constants/enums';
import DialogModal from '../../../../../../Dialog/Dialog';
import { DELETE_LANGUAGE_MODAL_TEXT, DELETE_LANGUAGE_MODAL_TITLE } from '../../../../constants/constants';

import { DeleteLanguageModalProps } from './DeleteLanguagesModal.types';

const DeleteLanguageModal = ({isOpen, setIsDeleteModalOpen, handleDeleteLanguage, handleSelectedLanguage}: DeleteLanguageModalProps) => {
    return (
        <DialogModal
            isOpen={isOpen}
            headline={DELETE_LANGUAGE_MODAL_TITLE}
            text={DELETE_LANGUAGE_MODAL_TEXT}
            buttons={[
                {
                    text: 'Cancel',
                    color: EButtonColor.SECONDARY,
                    variant: 'outlined',
                    func: () => {
                        setIsDeleteModalOpen(false);
                    }
                },
                {
                    text: 'Approve',
                    color: EButtonColor.PRIMARY,
                    variant: 'contained',
                    func: handleDeleteLanguage
                }
            ]}
            closeDialog={() => {
                setIsDeleteModalOpen(false);
                handleSelectedLanguage(null);
            }}
            className={"deleteLanguageModal"}
        />
    )
};

export default DeleteLanguageModal;
