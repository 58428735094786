import * as yup from 'yup';

export const addNewLanguageSchema = yup.object().shape({
    language: yup.string().required('Language display name is required'),
    country: yup.array()
        .of(yup.string())
        .test('not-empty', 'At least one country must be selected', (value) => {
            return !!(value && value.length > 0);
        })
        .test('no-default', 'Invalid country selected', (value) => {
            return !!(value && !value.includes('Country'));
        })
});
