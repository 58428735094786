import React, { useCallback } from 'react';

import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Box, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { capitalizeFirstLetter } from '../../../../../../../utils/formattingUtil';
import DataTable from '../../../../../../DataTable/DataTable';
import { handleRenderCountriesList } from '../../../../utils';

import { AddedLanguagesTableProps, AddLanguagesTableData, LanguageTableRow } from './AddedLanguagesTable.types';

const AddedLanguagesTable = ({ languagesList, isLoading, setIsDeleteLngModalOpen, setSelectedLanguage, setIsManageLngModalOpen}: AddedLanguagesTableProps) => {
    const columns = [
        {
            field: 'language',
            minWidth: 200,
            headerName: 'Language',
            flex: 0.2
        },
        {
            field: 'country',
            minWidth: 200,
            headerName: 'Country',
            flex: 1,
            renderCell: (params: any) => {
                const titleContent = params.row.country.length > 1 ? params.row.country.map((item: string) => capitalizeFirstLetter(item.toLowerCase())).join(', ') :
                    params.row.country;
                return (
                    <Tooltip title={titleContent}>
                        <span>{handleRenderCountriesList(params.row.country)}</span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'Actions',
            flex: 0,
            type: 'actions',
            width: 50,
            disableReorder: true,
            filterable: false,
            hideable: false,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<EditTwoToneIcon />}
                    label="Edit"
                    onClick={() => {
                        setIsManageLngModalOpen(true);
                        setSelectedLanguage(params.row)
                    }}
                    sx={{width: '162px'}}
                    showInMenu
                />,
                <GridActionsCellItem
                    className="danger"
                    icon={<DeleteOutlineTwoToneIcon className="danger"/>}
                    label="Delete"
                    onClick={() => {
                        setIsDeleteLngModalOpen(true);
                        setSelectedLanguage(params.row)
                    }}
                    sx={{width: '162px'}}
                    showInMenu
                />,
            ]
        }
    ]
    const getRowsData = useCallback(() => {
        return  languagesList.reduce((accumulator, currentItem: AddLanguagesTableData, index) => {
            const existingLanguage = accumulator.find((item: LanguageTableRow) => item.language === currentItem.language)
            if (existingLanguage) {
                existingLanguage._id = index.toString();
                existingLanguage.country = [...existingLanguage.country, currentItem.country]
                existingLanguage.countryCode = [...existingLanguage.countryCode, currentItem.countryCode]
            } else {
                accumulator.push({
                    ...currentItem,
                    country: [currentItem.country],
                    countryCode: [currentItem.countryCode],
                    _id: index.toString()})
            }
            return accumulator
        }, [] as LanguageTableRow[]);
    }, [languagesList])

    return (
        <Box className="languagesListTable">
            <DataTable
                columns={columns}
                rows={getRowsData()}
                loading={isLoading}
            />
        </Box>
    )
}

export default AddedLanguagesTable;
