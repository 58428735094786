import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  OfflinePinOutlined,
  RemoveCircleOutlineOutlined
} from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';

import useOffers from '../../api/useOffers';
import useUsers from '../../api/useUsers';
import { Offer, User } from '../../common/contracts';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DialogModal from '../../components/Dialog/Dialog';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import {
  EActiveState,
  EButtonColor,
  EFeatureFlag,
  ELocalStorageKeys,
  ENotificationType,
  EPopupSubType,
  OfferType
} from '../../constants/enums';
import { StatusLabel } from '../../design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from '../../design-system/StatusLabel/types';
import { useNotifications } from '../../hooks/useNotifications';
import { usePaginationFromURL } from '../../hooks/usePaginationFromURL';
import { AuthSliceState } from '../../store/store.types';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { permissionsUtil } from '../../utils/permissionsUtil';

import './style.scss';

const PopupsTable = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;
  const { fetchFeatureFlags } = useUsers(
    false,
    versionDetails,
    currentPublisherId,
    true
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { page, rows } = usePaginationFromURL('offers');
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const { getOffers, deleteOffer, updatePopUpActiveStatus } = useOffers(
    undefined,
    OfferType.POPUP,
    {
      currentPage,
      rowsPerPage
    }
  );
  const totalCount = getOffers.data?.totalCount || 0;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    navigate(`${location.pathname}?page=${currentPage}&rows=${rowsPerPage}`, {
      replace: true
    });
    getOffers.refetch();
  }, [currentPage, rowsPerPage]);

  const handleEditOfferUI = (offerId: string): void => {
    navigate(`./form/${offerId}`);
  };

  const handleDuplicateOffer = (offerId: string): void => {
    navigate(`./dup/${offerId}`);
  };

  const handleDeleteOffer = async () => {
    if (!selectedOffer) return;
    deleteOffer.mutate(selectedOffer, {
      onSuccess: () => {
        enqueueSnackbar(
          'Popup deleted successfully',
          ENotificationType.SUCCESS
        );
        getOffers.refetch();
      },
      onError: (e: any) => {
        enqueueSnackbar(
          'Failed to delete popup',
          ENotificationType.ERROR,
          e.response.data.message ?? ''
        );
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
        setSelectedOffer(null);
      }
    });
  };

  const handleToggleActiveStatus = async (
    offerId: string,
    isActive: boolean
  ) => {
    updatePopUpActiveStatus.mutate(
      { active: isActive, offerId },
      {
        onSuccess: () => {
          enqueueSnackbar(
            isActive
              ? 'Popup activated successfully'
              : 'Popup deactivated successfully',
            ENotificationType.SUCCESS
          );
          getOffers.refetch();
        },
        onError: (e: any) => {
          enqueueSnackbar(
            `Failed to ${isActive ? 'activate' : 'deactivate'} popup`,
            ENotificationType.ERROR,
            e.response.data.message ?? ''
          );
        }
      }
    );
  };

  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100
    },
    {
      field: 'offerId',
      flex: 1,
      headerName: 'ID',
      width: 100
    },
    {
      field: 'subType',
      flex: 1,
      headerName: 'Type',
      width: 200
    },
    {
      field: 'days',
      flex: 1,
      headerName: 'Days',
      width: 200,
      renderCell: (params: any) => {
        const filteredProducts = params.row.productsSequence.filter(
          (item: any) => item.products.length > 0
        );
        return filteredProducts?.length > 0 ? filteredProducts?.length : '';
      }
    },
    {
      field: 'segments',
      flex: 1,
      headerName: 'Segments',
      width: 200
    },
    {
      field: 'active',
      flex: 1,
      headerName: 'Status',
      width: 200,
      renderCell: (params: any) => (
        <StatusLabel
          text={`${params.row.active ? EActiveState.ACTIVE : EActiveState.INACTIVE}`}
          status={
            params.row.active ? EStatusLabel.ACTIVE : EStatusLabel.INACTIVE
          }
          prefixIcon={
            params.row.active ? (
              <CheckCircleOutlineIcon />
            ) : (
              <RemoveCircleOutlineOutlined />
            )
          }
        />
      )
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            handleEditOfferUI(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            (permissionsUtil.isNewVersion() &&
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.POPUPS,
                currentPublisherId,
                EPermissionAction.MANAGE
              ))
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<OfflinePinOutlined />}
          label={params.row.active ? 'Deactivate' : 'Activate'}
          onClick={() =>
            handleToggleActiveStatus(params.id, !params.row.active)
          }
          disabled={
            !permissionsUtil.canUserEdit() ||
            (permissionsUtil.isNewVersion() &&
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.POPUPS,
                currentPublisherId,
                EPermissionAction.MANAGE
              ))
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ContentCopyOutlinedIcon />}
          label="Duplicate"
          onClick={() => {
            handleDuplicateOffer(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            (permissionsUtil.isNewVersion() &&
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.POPUPS,
                currentPublisherId,
                EPermissionAction.MANAGE
              ))
          }
          showInMenu
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          onClick={() => {
            setIsDeleteDialogOpen(true);
            setSelectedOffer(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            (permissionsUtil.isNewVersion() &&
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.POPUPS,
                currentPublisherId,
                EPermissionAction.MANAGE
              ))
          }
          showInMenu
        />
      ]
    }
  ];

  const hasFeatureFlagPostPurchase =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_POST_PURCHASE
    ];

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Popups"
            buttons={[
              {
                text: 'Add New Popup',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden:
                  permissionsUtil.isNewVersion() &&
                  !permissionsUtil.isActionEnabled(
                    null,
                    EPermissionGroup.POPUPS,
                    currentPublisherId,
                    EPermissionAction.MANAGE
                  )
              }
            ]}
          />
        }
      >
        <AcContentWrapper>
          <DataTable
            pagination={true}
            className="popupsTable"
            rowIdIdentifier="offerId"
            columns={columns}
            rows={
              !getOffers.isLoading && getOffers.data
                ? getOffers.data.offers
                  ? getOffers.data.offers
                      .map((offer: Offer) => ({
                        // New format
                        _id: offer.publisherOfferId,
                        ...offer
                      }))
                      .filter((offer: Offer) =>
                        hasFeatureFlagPostPurchase
                          ? true
                          : offer.subType !== EPopupSubType.POST_PURCHASE
                      )
                  : getOffers.data.result
                      .filter((offer: Offer) => offer.type === 'SpecialOffer') // Old format
                      .map((offer: Offer) => ({
                        _id: offer.publisherOfferId,
                        ...offer
                      }))
                : []
            }
            loading={getOffers.isLoading || getOffers.isFetching}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
              setCurrentPage(0);
            }}
            currentPage={currentPage}
            totalCount={totalCount}
            defaultHiddenFields={[]}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
            onNoData={
              <FirstActionModal
                headline="Make your offers pop!"
                text="Create your first pop up by hitting the “Add new pop up” button on the top right corner of the screen"
              />
            }
          />
        </AcContentWrapper>
      </AcViewWrapper>

      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Popup"
        text="Are you sure you want to delete this Popup?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: handleDeleteOffer
          }
        ]}
        closeDialog={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default PopupsTable;
