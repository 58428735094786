import {
  EPermissionAction,
  EPermissionGroup,
  IPermissions
} from 'common/permissions.types';
import { NEW_VERSION } from 'constants/constants';

import {
  EPublisherSolutionModel,
  Project,
  User,
  UserRole
} from '../common/contracts';
import { ELocalStorageKeys } from '../constants/enums';

import { localStorageUtil } from './localStorageUtil';

export const permissionsUtil = {
  canUserEdit: () => {
    const userRole = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.role;
    return (
      userRole === UserRole.ADMIN ||
      userRole === UserRole.EDITOR ||
      userRole === UserRole.SUPER_ADMIN
    );
  },
  isAdmin: () => {
    const userRole = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.role;
    return userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN;
  },
  isViewerOrEditor: () => {
    const userRole = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.role;
    return userRole === UserRole.VIEWER || userRole === UserRole.EDITOR;
  },
  isSuperAdmin: () => {
    const userRole = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.role;
    return userRole === UserRole.SUPER_ADMIN;
  },
  isSuperAdminByProject: (activeProjectId: string) => {
    const projectDetails = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.projects;
    const userRoleByCurrentProject = projectDetails?.find(
      (project) => project.publisherId === activeProjectId
    )?.role;
    return userRoleByCurrentProject === UserRole.SUPER_ADMIN_NEW_VERSION;
  },
  generateTooltipEditPermissionString: () => {
    const userRole = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.role;
    return userRole === UserRole.VIEWER
      ? "You don't have access to perform this action"
      : '';
  },
  isNewVersion: () => {
    const versionDetails = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.version;
    return versionDetails === NEW_VERSION;
  },
  isCheckout: () => {
    const solutionModel = localStorageUtil.getAny<EPublisherSolutionModel>(
      ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL
    );
    return solutionModel === EPublisherSolutionModel.CHECKOUT;
  },
  shouldHideGroupInPermission: (
    permissions: IPermissions | undefined,
    group: EPermissionGroup,
    activeProject: string | undefined
  ) => {
    if (!permissions) {
      return false;
    }
    return !(permissions && activeProject
      ? permissions?.[activeProject]?.[group]
      : false);
  },
  canAccessSettings: (
    permissions: IPermissions | undefined,
    activeProject: string | undefined
  ) => {
    if (!activeProject) return true;
    return (
      permissions?.[activeProject]?.[EPermissionGroup.COMPANY] ||
      permissions?.[activeProject]?.[EPermissionGroup.USERS] ||
      permissions?.[activeProject]?.[EPermissionGroup.AUTHENTICATION]
    );
  },
  canAccessAnalytics: (permissions: IPermissions, project: string) => {
    if (!permissions) return false;
    return permissions?.[project]?.[EPermissionGroup.ANALYTICS];
  },
  canAccessBuilder: (permissions: IPermissions, project: string) => {
    if (!permissions) return false;
    return permissions?.[project]?.[EPermissionGroup.BUILDER];
  },
  canAccessUsers: (permissions: IPermissions, project: string) => {
    if (!permissions) return false;
    return permissions?.[project]?.[EPermissionGroup.USERS];
  },
  canAccessIntegration: (permissions: IPermissions, project: string) => {
    if (!permissions) return false;
    return permissions?.[project]?.[EPermissionGroup.INTEGRATION];
  },
  canAccessPublisher: (permissions: IPermissions, project: string) => {
    if (!permissions) return false;
    return permissions?.[project]?.[EPermissionGroup.COMPANY];
  },
  isActionEnabled: (
    permissions: IPermissions | null,
    group: EPermissionGroup,
    activeProject: string | null,
    action: EPermissionAction
  ) => {
    const pathParam = window.location.pathname.split('/')[2];
    const projectsDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.projects;
    const isProjectItem = projectsDetails?.some(
        (project) => project.publisherId === pathParam
    );

    permissions =
      permissions ||
      localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS);

    activeProject =
      activeProject ||
      localStorageUtil.get<string>(ELocalStorageKeys.PUBLISHER_ID) || (isProjectItem ? pathParam : null);

    if (!permissions || !activeProject) return false;
    return permissions?.[activeProject]?.[group]?.includes(action);
  },
  getPublishersByPayouts: (permissions: IPermissions) => {
    if (!permissions) return [];
    return Object.keys(permissions).filter(
      (key) => permissions[key][EPermissionGroup.PAYOUTS]
    );
  },
  getAccessPublisherOrders: (permissions: IPermissions) => {
    if (!permissions) return [];
    return Object.keys(permissions).filter(
      (key) => permissions[key][EPermissionGroup.ORDERS]
    );
  },
  getAccessPublisherReports: (permissions: IPermissions) => {
    if (!permissions) return [];
    return Object.keys(permissions).filter(
      (key) => permissions[key][EPermissionGroup.FINANCIAL_REPORT]
    );
  },
  getPublishersByFinancialReports: (permissions: IPermissions) => {
    if (!permissions) return [];
    return Object.keys(permissions).filter(
      (key) =>
        permissions[key][EPermissionGroup.FINANCIAL_REPORT] &&
        permissions[key][EPermissionGroup.PAYOUTS]
    );
  },
  isAnyOrdersOrPayoutsEnabled: (permissions: IPermissions) => {
    if (!permissions) return false;
    return Object.keys(permissions).some(
      (key) =>
        permissions[key][EPermissionGroup.ORDERS] ||
        permissions[key][EPermissionGroup.PAYOUTS]
    );
  },
  getRoleByPublisherId: (publisherId: string) => {
    return !publisherId
      ? null
      : localStorageUtil
          .get(ELocalStorageKeys.USER_DETAILS)
          ?.projects?.find((p: Project) => p?.publisherId === publisherId)
          ?.role;
  }
};
