const separatorOptions = [
  {
    content: 'Comma',
    key: ',',
    value: ','
  },
  {
    content: 'Dot',
    key: '.',
    value: '.'
  },
  {
    content: 'Space',
    key: ' ',
    value: ' '
  }
];

const trueFalseOptions = [
  {
    content: 'Yes',
    key: 'true',
    value: 'true'
  },
  {
    content: 'No',
    key: 'false',
    value: 'false',
    selected: true
  }
];

export const adminSettingsUtils = {
  separatorOptions,
  trueFalseOptions
};
