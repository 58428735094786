import React from 'react';

import { ESectionViewModel } from 'constants/enums';
import getSectionLayout from 'utils/getSectionLayout';

import { SectionsItem } from 'components/Theme/StoreTheme/storeTheme.types';

import '../../../../style/forms.scss';

interface ISectionLayoutProps {
  sectionViewModel: ESectionViewModel;
  section: Partial<SectionsItem>;
}

export const SectionLayout: React.FC<ISectionLayoutProps> = ({
  sectionViewModel,
  section
}) => {
  if (!section || !(Object.keys(section).length > 0) || !sectionViewModel) {
    return null;
  }

  const SectionLayout = getSectionLayout(sectionViewModel as ESectionViewModel);

  return (
    <div className="offers-form-sections">
      <SectionLayout
        key={section.publisherSectionId}
        sectionName={section.name || ''}
        image={section.image}
      />
    </div>
  );
};
