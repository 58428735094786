import React, { useMemo, useState } from 'react';

import ReactCountryFlag from "react-country-flag"
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useLocalization from '../../../../../../../api/useLocalization';
import { EButtonVariant } from '../../../../../../../constants/enums';
import { AuthSliceState } from '../../../../../../../store/store.types';
import AcInput from '../../../../../../AcInput/AcInput';
import AcSelect from '../../../../../../AcSelect/AcSelect';
import ActionButton from '../../../../../../ActionButton/ActionButton';
import { handleRenderCountriesList } from '../../../../utils';

import { ManageLanguageFormProps } from './ManageLanguageForm.types';
import { addNewLanguageSchema } from './ValidationSchema';

import './style.scss';

const ManageLanguageForm = ({ countriesList, selectedLanguage, onSave, btnVariant = EButtonVariant.OUTLINED, btnText= "Add New"}: ManageLanguageFormProps) => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { getAddedLanguagesList} = useLocalization(currentPublisherId);

    const defaultCountryOption = {
        content: 'Country', key: 'Country', value: 'Country'
    }
    const [selectedCountries, setSelectedCountries] = useState<string[]>(selectedLanguage?.country ?? [defaultCountryOption.value]);

    const countriesOptions = useMemo(() => {
        return [...Object.entries(countriesList).map(([key, value]) => ({
            content: value,
            key: key,
            value: value,
            renderFunction: () => (
                <Stack className="manageLanguageForm__countryItem" direction="row" alignItems="center" gap={1}>
                    <ReactCountryFlag className="manageLanguageForm__countryFlag" countryCode={key} svg />
                    <Typography>{value}</Typography>
                </Stack>

            )
        }))]
    }, [countriesList])

    const handleSelectCountry = (arrSelectedCountries: string[]) => {
        const newSelectedValues = arrSelectedCountries.filter((item: string) => item !== defaultCountryOption.value)
        setFieldValue("country", newSelectedValues, true);
        setSelectedCountries(newSelectedValues);
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        isValid,
        setFieldValue
    } = useFormik({
        initialValues: {
            language: selectedLanguage?.language ?? '',
            country: selectedLanguage?.country ?? [defaultCountryOption.value]
        },
        validationSchema: addNewLanguageSchema,
        onSubmit: (values, {resetForm}) => {
            const parsedLanguageData = values.country.map(country => ({
                language: values.language,
                country: countriesOptions.find(option => option.value === country)?.key as string ?? ''
            }));

            if (!selectedLanguage) {
                const newLanguagesList = getAddedLanguagesList?.data ? [...getAddedLanguagesList.data, ...parsedLanguageData] : parsedLanguageData;
                onSave(newLanguagesList);
            } else {
                onSave(parsedLanguageData);
            }
            setSelectedCountries([defaultCountryOption.value]);
            resetForm();
        }
    })

    return (
        <form className="manageLanguageForm" onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="flex-start" className="manageLanguageFormContainer">
                <AcInput
                    header="Language Display Name"
                    name="language"
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass="manageLanguageForm__input"
                    placeholder={'Language'}
                    error={touched.language && !!errors.language}
                    helperText={(touched.language && errors.language) || ''}
                />
                <AcSelect
                    name="country"
                    header="Country"
                    value={values.country}
                    setSelectedItems={(values: string[]) =>  handleSelectCountry(values)}
                    items={countriesOptions}
                    defaultCheckedItems={[defaultCountryOption.value]}
                    isApplyBtnEnabled={false}
                    renderValue={() => handleRenderCountriesList(selectedCountries)}
                    className="manageLanguageForm__countrySelect"
                    error={!!errors.country}
                    helperText={(errors.country as string) || ''}
                    isCheckBox
                />
                <ActionButton
                    variant={btnVariant}
                    text={btnText}
                    onClick={submitForm}
                    disabled={!isValid}
                />
            </Stack>
        </form>
    )
}

export default ManageLanguageForm;
