import { useEffect, useRef,useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  createTheme,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  ThemeProvider} from '@mui/material';
import { Box } from '@mui/system';
import { EOrderStatus } from 'constants/enums';

import { statusDictionary } from 'components/Orders/OrdersTable/OrdersTable.types';

import { AcSelectProps } from './AcSelect.types';

import './style.scss';

const AcSearchSelect = ({
  items = [],
  className = '',
  isRepeatSelect,
  header = '',
  helperText = '',
  tooltip = '',
  uploadConfig = undefined,
  required = false,
  headerSize = '14',
  isCheckBox = false,
  isLimited,
  setSelectedItems = () => {},
  loading = false,
  defaultCheckedItems,
  isSelectAllEnabled = false,
  isApplyBtnEnabled = true,
  isFree,
  selectOptions = true,
  ...props
}: AcSelectProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>(
    defaultCheckedItems ?? []
  );
  const [isOpen, setIsOpen] = useState(false);
  const applyButtonRef = useRef<HTMLButtonElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setCheckedItems(props.value);
  }, [props.value]);

  const handleCheckboxClick = (checkedItem: any, key?: string | number) => {
    if (isSelectAllEnabled && key === 'all') {
      let newSelectedValues: any = items.map((item) => item.value);
      if (checkedItems.length === newSelectedValues.length) {
        newSelectedValues = [];
      }
      setCheckedItems(newSelectedValues);
      if (!isApplyBtnEnabled) {
        setSelectedItems(newSelectedValues);
      }
      return;
    }
    let newCheckedItems;
    if (checkedItems.includes(checkedItem)) {
      newCheckedItems = checkedItems.filter((item) => item !== checkedItem);
    } else {
      newCheckedItems = isLimited
        ? [checkedItem]
        : [...checkedItems, checkedItem];
    }
    setCheckedItems(newCheckedItems);
    if (!isApplyBtnEnabled) {
      setSelectedItems(newCheckedItems);
    }
  };

  const handleIsItemChecked = (filterBy: any) => {
    if (isSelectAllEnabled && filterBy === 'all') {
      return checkedItems.length === items.length;
    }
    return checkedItems.includes(filterBy);
  };

  const renderSelectItems = () => {
    if (!Array.isArray(items)) {
      return [];
    }
    return items?.map((item, index) => {
      const { renderFunction, filterBy, ...itemProps } = item ?? {};
      const content = renderFunction
        ? renderFunction(itemProps)
        : itemProps.content;

      return (
        <MenuItem
          {...itemProps}
          key={itemProps.key || index}
          sx={{
            padding: isCheckBox ? '8px 12px' : '6px 16px',
            fontSize: isCheckBox ? '14px' : '16px'
          }}
          onClick={(event) => {
            event.stopPropagation(); // Prevent Select from closing
            isCheckBox && handleCheckboxClick(filterBy, itemProps?.key);
          }}
        >
          {isCheckBox && (
            <Checkbox
              checked={handleIsItemChecked(filterBy)}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxClick(filterBy, itemProps?.key);
              }}
              size="small"
              sx={{
                padding: 0,
                marginRight: '6px',
                '&.Mui-checked': {
                  color: '#7122FF'
                }
              }}
            />
          )}
          {content}
        </MenuItem>
      );
    });
  };

  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            '&::before': {
              content: checkedItems.length > 0 ? '"|"' : '""',
              left: '50px',
              padding: '0 5px',
              color: '#CACBD4'
            }
          }
        }
      }
    }
  });

  const renderStatusValue = (selected: string | string[]) => {
    const selectedLength = selected?.length;
    const selectedValue = selectedLength > 1 ? selected[0] : selected;
    const statusText =
      isFree &&
      statusDictionary[selectedValue as EOrderStatus]?.text ===
        'Failed (charged)'
        ? 'Failed'
        : selectOptions ? statusDictionary[selectedValue as EOrderStatus]?.text : selectedValue;
    if (selectedLength <= items?.length && selectedLength > 1)
      return `${statusText} and ${selectedLength - 1} more`;
    return statusText;
  };

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node) &&
      !applyButtonRef.current?.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Stack
        className="select-container formContent-select"
        direction="column"
        ref={selectRef}
      >
        {loading && <CircularProgress size={12} />}
        <>
          {props.onClear && props?.value && (
            <div className="select-block__close-btn">
              <IconButton onClick={props.onClear} size="small">
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </div>
          )}
          <Stack className="input-container formContent-input">
            <ThemeProvider theme={theme}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  {...props}
                  open={isOpen}
                  onClick={() => {
                    if (selectOptions) setIsOpen(!isOpen)
                  }}
                  onClose={() => setIsOpen(false)}
                  label={header}
                  disabled={props.disabled || loading}
                  multiple={isCheckBox}
                  ref={selectRef}
                  startAdornment={
                    props.value.length > 0 ? (
                      <HighlightOffIcon
                        onClick={() => setSelectedItems([])}
                        className="search-open-icon"
                      />
                    ) : (
                      <AddCircleOutlineIcon
                        onClick={() => setIsOpen(true)}
                        className="search-open-icon"
                      />
                    )
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '280px',
                        width: isCheckBox ? '200px' : 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                      }
                    },
                    MenuListProps: {
                      style: {
                        paddingTop: isCheckBox ? '9px' : '8px',
                        paddingBottom: isCheckBox ? 0 : '8px',
                        flexGrow: 1,
                        overflowY: 'auto'
                      }
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    }
                  }}
                  className="formContent-searchInput-searchField"
                  renderValue={renderStatusValue}
                >
                  {isCheckBox && selectOptions ? (
                    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                      {isSelectAllEnabled && (
                        <MenuItem
                          key="all"
                          sx={{
                            padding: '8px 12px',
                            fontSize: '14px'
                          }}
                          onClick={() => handleCheckboxClick('all', 'all')}
                        >
                          <Checkbox
                            checked={handleIsItemChecked('all')}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleCheckboxClick('all', 'all');
                            }}
                            size="small"
                            sx={{
                              padding: 0,
                              marginRight: '6px'
                            }}
                          />
                          Select all
                        </MenuItem>
                      )}
                      {renderSelectItems()}
                    </Box>
                  ) : selectOptions && (
                    renderSelectItems()
                  )}
                  {isCheckBox && isApplyBtnEnabled && selectOptions && (
                    <div
                      className="apply-button--container"
                      style={{ padding: '6px 8px' }}
                    >
                      <Button
                        ref={applyButtonRef}
                        size="small"
                        variant="contained"
                        disabled={false}
                        onClick={() => {
                          setSelectedItems(checkedItems);
                          setIsOpen(false);
                        }}
                        disableElevation={true}
                      >
                        Apply
                      </Button>
                    </div>
                  )}
                </Select>
              </FormControl>
            </ThemeProvider>
          </Stack>
        </>
      </Stack>
    </ClickAwayListener>
  );
};

export default AcSearchSelect;
