import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Stack } from '@mui/system';

import useLocalization from '../../../api/useLocalization';
import { AuthSliceState } from '../../../store/store.types';

import LanguagesListSection from './components/LanguagesListSection/LanguagesListSection';
import ExportTranslationsCSVSection from './components/TranslationsCSVSection/ExportTranslationsCSVSection';
import ImportTranslationsCSVSection from './components/TranslationsCSVSection/ImportTranslationsCSVSection';
import { LanguageDataParams } from './components/types';

const LocalizationSettings = () => {
    const [exportSectionActive, setExportSectionActive] = useState(false);
    const [importSectionActive, setImportSectionActive] = useState(false);
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { getAddedLanguagesList} = useLocalization(currentPublisherId);

    useEffect(() => {
        const hasCustomLanguages = getAddedLanguagesList?.data?.some((data: LanguageDataParams) => data.language !== 'en');
        setExportSectionActive(hasCustomLanguages);
    }, [getAddedLanguagesList.data]);

    return (
        <Stack sx={{marginTop: '-24px'}}>
            <LanguagesListSection />
            <ExportTranslationsCSVSection
                isActive={exportSectionActive}
                handleSectionActive={() => setImportSectionActive((prevState) => !prevState && true)}
            />
            <ImportTranslationsCSVSection isActive={importSectionActive} />
        </Stack>
    );
}

export default LocalizationSettings;
