import { useMutation, useQuery } from '@tanstack/react-query';

import { LanguageDataParams } from '../components/Settings/LocalizationSettings/components/types';
import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

const useLocalization = (publisherId: string | null) => {
    const axios = useAxios();

    const getAddedLanguagesList = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.LOCALIZATION_ADDED_LANGUAGES_LIST],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/languages`,
                {},
                publisherId
                    ? { 'x-publishers': publisherId }
                    : undefined
            )
        }
    })

    const addNewLanguage = useMutation(async(newLanguagesData: LanguageDataParams[]) => {
        const response = await axios.post(
            `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/languages`,
            {languages: newLanguagesData},
            publisherId ? { 'x-publishers': publisherId } : undefined
        )
        return response.data
    }, {})

    const editLanguage = useMutation(async(newLanguagesData: LanguageDataParams[]) => {
        return await axios.post(
            `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/languages`,
            {languages: newLanguagesData},
            publisherId ? { 'x-publishers': publisherId } : undefined
        )
    }, {})

    const deleteLanguage = useMutation(async (languagesData: LanguageDataParams[]) => {
        return await axios.post(
            `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/languages`,
            {languages: languagesData},
            publisherId ? { 'x-publishers': publisherId } : undefined
        )
    }, {});

    const importStaticTranslationsCsvFile = useMutation(async (newFile: FormData) => {
        const response = await axios.post(
            `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/files`,
            newFile,
            publisherId ? { 'x-publishers': publisherId } : undefined
        )
        return response.data
    });

    const importDynamicTranslationsCsvFile = useMutation(async (newFile: FormData) => {
        const response = await axios.post(
            `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/dynamic-files`,
            newFile,
            publisherId ? { 'x-publishers': publisherId } : undefined
        )
        return response.data
    });

    const getStaticTranslationsCsvFile = useQuery({
        ...fetchConfig.single,
        queryKey: [EQueryKeys.LOCALIZATION_STATIC_TRANSLATIONS_FILE],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/files`,
                {},
                publisherId
                    ? { 'x-publishers': publisherId }
                    : undefined
            )
        },
        enabled: false,
        retry: false
    })

    const getDynamicTranslationsCsvFile = useQuery({
        ...fetchConfig.single,
        queryKey: [EQueryKeys.LOCALIZATION_DYNAMIC_TRANSLATIONS_FILE],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.LOCALIZATION_TRANSLATIONS)}/dynamic-files`,
                {},
                publisherId
                    ? { 'x-publishers': publisherId }
                    : undefined
            )
        },
        enabled: false,
        retry: false
    })

    return {
        getAddedLanguagesList,
        addNewLanguage,
        deleteLanguage,
        importStaticTranslationsCsvFile,
        importDynamicTranslationsCsvFile,
        getStaticTranslationsCsvFile,
        getDynamicTranslationsCsvFile,
        editLanguage
    }
}

export default useLocalization;
