import { useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import useLocalization from '../../../../../api/useLocalization';
import { DATE_TIME_FORMAT } from '../../../../../constants/constants';
import { ENotificationType, UploadTypes } from '../../../../../constants/enums';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { AuthSliceState } from '../../../../../store/store.types';
import AcCard from '../../../../AcCard/AcCard';
import ActionButton from '../../../../ActionButton/ActionButton';
import ImportCSVModal from '../../../../ImportCSVModal/ImportCSVModal';

import './style.scss';

const ImportTranslationsCSVSection = ({isActive } : {isActive: boolean}) => {
    const formattedDate = dayjs().utc().format(DATE_TIME_FORMAT)
    const fileRef = useRef<HTMLInputElement>(null);
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const [activeUploadType, setActiveUploadType] = useState<UploadTypes | null>(null);
    const {importStaticTranslationsCsvFile, importDynamicTranslationsCsvFile } = useLocalization(currentPublisherId);
    const { enqueueSnackbar } = useNotifications();

    const handleStaticImportCSV = (formData: FormData) => {
        importStaticTranslationsCsvFile.mutate(formData, {
            onSuccess: async () => {
                enqueueSnackbar(
                    "Static translations file has been uploaded successfully",
                    ENotificationType.SUCCESS
                );
                setActiveUploadType(null);
            },
            onError(error) {
                enqueueSnackbar(
                    "Static translations file has failed to upload",
                    ENotificationType.ERROR,
                    (
                        (error as AxiosError).response?.data as {
                            message: string;
                        }
                    ).message
                );
            }
        })
    }

    const handleDynamicImportCSV = (formData: FormData) => {
        importDynamicTranslationsCsvFile.mutate(formData, {
            onSuccess: async () => {
                enqueueSnackbar(
                    "Dynamic translations file has been uploaded successfully",
                    ENotificationType.SUCCESS
                );
                setActiveUploadType(null);
            },
            onError(error) {
                enqueueSnackbar(
                    "Dynamic translations file has failed to upload",
                    ENotificationType.ERROR,
                    (
                        (error as AxiosError).response?.data as {
                            message: string;
                        }
                    ).message
                );
            }
        })
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} sx={{position: "relative"}}>
                <AcCard
                    title={'3. Upload New Translations file'}
                    description={'Upload a CSV with translations for static text (e.g., labels, buttons) or dynamic content that varies by user'}
                    stackContainer={false}
                    className="localizationCSVCard"
                    customTitleClass="localizationCSVCardTitle"
                    customDescriptionClass="localizationCSVCardDescription"
                    customStyles={{"border": "1px solid #cacbd4", "borderRadius": "4px"}}
                >
                    <Stack direction="row" spacing={2}>
                        <ActionButton
                            variant="outlined"
                            text="Upload Static strings"
                            onClick={() => setActiveUploadType(UploadTypes.STATIC)}
                        />
                        <ActionButton
                            variant="outlined"
                            text="Upload Dynamic Strings"
                            onClick={() => setActiveUploadType(UploadTypes.DYNAMIC)}
                        />
                    </Stack>
                    <Typography variant="body2" sx={{marginTop: '14px', fontSize: '14px', fontFamily: `'Montserrat', sans-serif`}}>{"Last upload:"} {formattedDate}</Typography>
                </AcCard>
                {!isActive && (
                    <Box className="disabledBox" />
                )}
                <ImportCSVModal
                    isOpen={!!activeUploadType}
                    onCloseModal={() => setActiveUploadType(null)}
                    fileRef={fileRef}
                    onImportFile={(formData: FormData) => activeUploadType === UploadTypes.STATIC ? handleStaticImportCSV(formData) : handleDynamicImportCSV(formData)}
                    fileKeyName="languagesFile"
                />
            </Grid>
        </Grid>
    )
};

export default ImportTranslationsCSVSection;
