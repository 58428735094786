import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { AcSwitchSelectorProps } from "common/contracts";
import { EScheduleType } from "constants/enums";

import './style.scss';

const AcSwitchSelector = ({ options, value, onClick }: AcSwitchSelectorProps) => {
    const [selected, setSelected] = useState<string>(EScheduleType.PERMANENT);

    useEffect(() => {
        setSelected(value ? EScheduleType.PERMANENT : EScheduleType.SCHEDULED);
    }, [value]);

    return (
        <Box className="ac-switch-selector">
            {options.map((option) => (
                <Button
                    key={option.value}
                    className={`ac-switch-selector-button ${option.value === selected ? 'active' : ''}`}
                    onClick={() => onClick(option.value)}
                    disableRipple
                    sx={{ cursor: 'default' }}
                >
                    <Typography>{option.value}</Typography>
                </Button>
            ))}
        </Box>
    );
};

export default AcSwitchSelector;